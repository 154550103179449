import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import "./Registry.css";
import imgGoBack from '../../img/go_back.png';
import { Button, CheckBox, LoadPanel, Popup } from 'devextreme-react';
import SelectBox from "devextreme-react/select-box";
import TextBox from 'devextreme-react/text-box';
import TextArea, { TextAreaTypes } from 'devextreme-react/text-area';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { RemoteDocuments } from './RemoteDocuments';
import { LocalDocuments } from './LocalDocuments';
import { SessionContext } from '../context/SessionContext';
import imgSpinner from '../../img/spinner.gif';
import { RegistryEvents } from './RegistryEvents';


//const renderStateField = (data) => {
//    return (
//        <div style={(data != undefined && data != null) ? { backgroundColor: data.state.bgColor, color: data.state.foreColor, height: "100%" } : {}}>
//            <TextBox style={(data != undefined && data != null) ? { backgroundColor: data.state.bgColor, color: data.state.foreColor, display: "inline", height: "100%" } : { display: "inline" }}
//                defaultValue={(data != undefined && data != null) ? data.state.name : ""}
//                readOnly={true}
//                onContentReady={(e) => {
//                    if (data != undefined && data != null) {
//                        let listItem = e.component.element().querySelector(".dx-texteditor-input");
//                        listItem.style.color = data.state.foreColor;
//                        listItem.style.fontFamily = "roboto-bold";
//                    }
//                }}
//            />
//        </div>
//    );
//}

export const Registry = () => {
    const { idRegistry, idDealer, idProcedure } = useParams();

    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);

    let history = useHistory();

    const [loadingState, setLoadingState] = useState(true);
    const [editionState, setEditionState] = useState(false);

    const [nameInputState, setNameInputState] = useState("");
    const [errorState, setErrorState] = useState("");

    const [registryState, setRegistryState] = useState({ loading: true, result: null });
    const [usersState, setUsersState] = useState({ loading: true, result: null });
    const [stateState, setStateState] = useState({ loading: true, result: null });

    const [dealerState, setDealerState] = useState({ loading: true, result: null });

    const [stateComboState, setStateComboState] = useState(null);
    const [userComboState, setUserComboState] = useState(null);


    const [exportedSigaChkState, setExportedSigaChkState] = useState(false);

    const [dataState, setDataState] = useState([]);
    const [remoteDataState, setRemoteDataState] = useState([]);
    const [docsState, setDocsState] = useState([]);
    const [remoteDocsState, setRemoteDocsState] = useState([]);
    const [extraDocsState, setExtraDocsState] = useState([]);
    const [extraRemoteDocsState, setExtraRemoteDocsState] = useState([]);
    const [incidenceState, setIncidenceState] = useState("");
    const [notifyToStringState, setNotifyToStringState] = useState("");


    const [dataFormState, setDataFormState] = useState([]);
    const [remoteDataFormState, setRemoteDataFormState] = useState([]);


    const renderStateField = useCallback((data) => {
        return (
            <div style={(data != undefined && data != null) ? { backgroundColor: data.state.bgColor, color: data.state.foreColor, height: "100%" } : {}}>
                <TextBox style={(data != undefined && data != null) ? { backgroundColor: data.state.bgColor, color: data.state.foreColor, display: "inline", height: "100%" } : { display: "inline" }}
                    defaultValue={(data != undefined && data != null) ? data.state.name : ""}
                    readOnly={true}
                    onContentReady={(e) => {
                        if (data != undefined && data != null) {
                            let listItem = e.component.element().querySelector(".dx-texteditor-input");
                            listItem.style.color = data.state.foreColor;
                            listItem.style.fontFamily = "roboto-bold";
                        }
                    }}
                />
            </div>
        );
    }, []);



    const loadRegistry = async () => {

        if (Number(idRegistry) > 0) {
            return fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistry?id=${idRegistry}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        setRegistryState({ loading: false, result: result.response });
                        setStateComboState(result.response.registry.stateId);
                        setUserComboState(result.response.registry.assigned);
                        setExportedSigaChkState(result.response.registry.exported);
                        //setNameInputState(result.response.name);

                        return true;
                    } else {
                        let currentUrl = history.location.pathname;
                        let backlog = [];
                        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                            backlog = history.location.state.comesFrom;
                        }
                        backlog.push(currentUrl);
                        history.replace(`/registries/${idDealer}`, { comesFrom: backlog });
                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        } else {
            let currentUrl = history.location.pathname;
            let backlog = [];
            if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                backlog = history.location.state.comesFrom;
            }
            backlog.push(currentUrl);
            history.replace(`/registries/${idDealer}`, { comesFrom: backlog });
            return false;
        }
    };

    const downloadRegistry = async () => {

        if (Number(idRegistry) > 0) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/DownloadRegistry?id=${idRegistry}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        downloadB64(result.response, registryState.result.registry.fullId, ".zip");
                        return true;
                    } else {

                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        }
    };
    const exportRegistrySIGA = async () => {

        if (Number(idRegistry) > 0) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/ExportRegistrySIGA?id=${idRegistry}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        downloadB64(result.response, registryState.result.registry.fullId, ".xml");
                        return true;
                    } else {

                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        }
    };



    const downloadB64 = (fileB64, fileName, extension) => {
        const linkSource = `data:application/zip;base64,${fileB64}`;
        const downloadLink = document.createElement("a");
        const fname = fileName + extension;
        downloadLink.href = linkSource;
        downloadLink.download = fname;
        downloadLink.click();
    }

    const loadDealer = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/dealers/GetId?id=${idDealer}`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setDealerState({ loading: false, result: result.response });
                return result.response;
            })
            .catch((err) => {
                throw 'Network error'
                return false;
            });
    }
    const loadStates = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetStates?idProcedure=${idProcedure}`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setStateState({ loading: false, result: result.response })
                return true;
            })
            .catch((err) => {
                throw 'Network error'
                return false;
            });
    };
    const loadStates_depr = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetAllProcedureStates`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setStateState({ loading: false, result: result.response })
                return true;
            })
            .catch((err) => {
                throw 'Network error'
                return false;
            });
    };



    const loadUsers = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/users`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setUsersState({ loading: false, result: result.response });

                return result.response;
            })
            .catch((err) => {
                throw 'Network error'
                return false;
            });
    }

    const validateRegistry = async () => {
        let error = "";
        if (userComboState == null || userComboState <= 0) {
            error = "No se ha asignado el trámite a un usuario.";
        }
        if (stateComboState == null || stateComboState <= 1) {
            error = "No se ha asignado un estado al trámite.";
        }
        //if the state that is set is  4 (incidencia) incidenceState can't be empty.
        if (stateComboState == 4 && incidenceState.trim() == "") {
            error = "Debe establecerse un texto de incidencia si el estado es 'Incidencia'.";
        } else if (stateComboState == 6) {
            //if the state that is set is  6 (finalizado) we should check all mandatory local data and docs is available.
            let isAnyDocMissing = false;
            docsState.forEach((d) => {
                if (d[0].mandatory && (d[1] == null || d[1] == "")) {
                    isAnyDocMissing = true;
                }
            });
            let isAnyDataMissing = false;
            dataState.forEach((d) => {
                if (d[0].mandatory) {
                    const valorEnCampo = dataFormState.find(e => e[0] == String(d[0].id))[1];
                    if (valorEnCampo == undefined || valorEnCampo.trim() == "") {
                        isAnyDataMissing = true;
                    }
                }
            });

            if (isAnyDocMissing || isAnyDataMissing) {
                error = "No ha sido posible finalizar el trámite porque deben rellenarse todos los datos y documentos obligatorios a aportar por la gestoría (marcados con '*') para poder establecer el estado a 'Finalizado'. Revísalo de nuevo.";
            }
        }
        return error;
    }
    const reload = () => {
        loadAll();
    }

    //const loadDatas = async () => {
    //    fetch(`./api/registrys/GetData?idRegistry=${id}&remote=0`)
    //        .then(response => response.json())
    //        .then(result => {
    //            // You can process the response here
    //            console.log(result.response)
    //            setDataState(result.response)
    //        })
    //        .catch(() => { throw 'Network error' });
    //    fetch(`./api/registrys/GetData?idRegistry=${id}&remote=1`)
    //        .then(response => response.json())
    //        .then(result => {
    //            // You can process the response here
    //            console.log(result.response)
    //            setRemoteDataState(result.response)
    //        })
    //        .catch(() => { throw 'Network error' });
    //};
    //const loadDocs = async () => {
    //    fetch(`./api/registrys/GetDocs?idRegistry=${id}&remote=0`)
    //        .then(response => response.json())
    //        .then(result => {
    //            // You can process the response here
    //            console.log(result.response)
    //            setDocsState(result.response)
    //        })
    //        .catch(() => { throw 'Network error' });
    //    fetch(`./api/registrys/GetDocs?idRegistry=${id}&remote=1`)
    //        .then(response => response.json())
    //        .then(result => {
    //            // You can process the response here
    //            console.log(result.response)
    //            setRemoteDocsState(result.response)
    //        })
    //        .catch(() => { throw 'Network error' });
    //};

    const loadAll = async () => {
        setLoadingState(true);
        Promise.all([loadRegistry(), loadUsers(), loadStates(), loadDealer()]).then(() => {
            setLoadingState(false);
        });
    }


    const saveEdit = async (exitAfterSuccess) => {
        validateRegistry().then((res) => {
            if (res != "") {
                console.log(res);

                showGenericDialog(stateComboState == 6 ? "No ha sido posible finalizar el trámite" : "No guardado", ["No ha sido posible guardar el trámite porque se ha detectado el siguiente problema:  ", <><br /><strong style={{ color: "var(--rojo-ko)" }}>{res}</strong></>], "Ok", null, () => { hideGenericDialog() }, () => { })
            } else {
                setLoadingState(true);
                let dataJson = JSON.stringify([...dataFormState, ...remoteDataFormState]);
                fetch(`${process.env.PUBLIC_URL}/api/registries/PutRegistry?idProcedure=${idProcedure}&idRegistry=${idRegistry}&state=${stateComboState}&user=${userComboState}&data=${encodeURIComponent(dataJson)}&incidence=${encodeURIComponent(incidenceState)}&exported=${exportedSigaChkState ? "1" : "0"}`,
                    {
                        method: 'PUT',
                        body: '',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => response.json())
                    .then(result => {
                        // You can process the response here
                        console.log(result.response)
                        setLoadingState(false);
                        if (result.response != null) {
                            reload();
                            if (exitAfterSuccess) {
                                let currentUrl = history.location.pathname;
                                let backlog = [];
                                if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                    backlog = history.location.state.comesFrom;
                                }
                                backlog.push(currentUrl);
                                history.replace(`/registries/${idDealer}`, { comesFrom: backlog });
                            } else {
                                if (stateComboState == 6) {
                                    //If the state of the registry after saving is 'finalizado' we should not be able to continue editing it.
                                    setEditionState(false);
                                }
                            }
                        }
                    })
                    .catch(() => {
                        throw 'Network error'
                        setLoadingState(false);
                    });

            }
        })
    }


    const [genericDialogState, setGenericDialogState] = useState({
        title: "",
        body: "",
        okText: "",
        koText: "",
        okCallback: () => {

        },
        koCallback: () => {

        },
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showGenericDialog = (title, body, okText, koText, okCallback, koCallback) => {
        setGenericDialogState({
            title: title,
            body: body,
            okText: okText,
            koText: koText,
            okCallback: okCallback,
            koCallback: koCallback,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideGenericDialog = () => {
        setGenericDialogState({
            title: "",
            body: "",
            okText: "",
            koText: "",
            okCallback: () => {

            },
            koCallback: () => {

            },
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }

    const renderStateItem = (data) => {
        return (
            <div className="custom-item">
                <div style={(data != undefined && data != null) ? { backgroundColor: data.state.bgColor, color: data.state.foreColor, padding: "7px 9px" } : {}}>{(data != undefined && data != null) ? data.state.name : ""}</div>
            </div>
        );
    }

    const stateDataSource = useMemo(() => {
        const retVal = stateState.loading || stateState.result == undefined || stateState.result == null || registryState.result == undefined || registryState.result == null ? [] : stateState.result.filter((e) => e.shown && e.state != null && e.state.id != null && e.state.id != 1).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

        return retVal;

    }, [stateState, registryState]);

    //const stateDataSource_DEPR = useMemo(() => {
    //    const retVal = stateState.loading || stateState.result == undefined || stateState.result == null || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null || stateState.result[String(registryState.result.registry.procedureId)] == undefined || stateState.result[String(registryState.result.registry.procedureId)] == null ? [] : stateState.result[String(registryState.result.registry.procedureId)].filter((e) => e.shown && e.state != null && e.state.id != null && e.state.id != 1).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    //    return retVal;

    //}, [stateState, registryState]);

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    useEffect(async () => {
        await fetch(`${process.env.PUBLIC_URL}/api/notifications/MarkAsSeen?idRegistry=${idRegistry}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                //setNotificationsState(result.response);
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }, []);


    const prepareData = () => {
        if (!registryState.loading) {
            var remoteDataResult = [];
            var dataResult = [];
            var remoteDocsResult = [];
            var docsResult = [];
            var remoteExtraDocsResult = [];
            var extraDocsResult = [];
            for (const property in registryState.result.datas) {
                if (registryState.result.datas[property].item1.remote) {
                    remoteDataResult.push([registryState.result.datas[property].item1, registryState.result.datas[property].item2]);
                } else {
                    dataResult.push([registryState.result.datas[property].item1, registryState.result.datas[property].item2]);
                }
            }
            for (const property in registryState.result.docs) {
                if (registryState.result.docs[property].item1.remote) {
                    remoteDocsResult.push([registryState.result.docs[property].item1, registryState.result.docs[property].item2]);
                } else {
                    docsResult.push([registryState.result.docs[property].item1, registryState.result.docs[property].item2]);
                }
            }
            for (const property in registryState.result.extraDocs) {
                if (registryState.result.extraDocs[property].item1.remote) {
                    remoteExtraDocsResult.push([registryState.result.extraDocs[property].item1, registryState.result.extraDocs[property].item2]);
                } else {
                    extraDocsResult.push([registryState.result.extraDocs[property].item1, registryState.result.extraDocs[property].item2]);
                }
            }
            setRemoteDataState(remoteDataResult);
            setDataState(dataResult);
            setRemoteDocsState(remoteDocsResult);
            setDocsState(docsResult);
            setExtraRemoteDocsState(remoteExtraDocsResult);
            setExtraDocsState(extraDocsResult);
            var remoteDataForm = remoteDataResult.map((e) => {
                return [String(e[0].id), e[1]];
            });
            setRemoteDataFormState(remoteDataForm);
            var dataForm = dataResult.map((e) => {
                return [String(e[0].id), e[1]];
            });
            setIncidenceState(registryState.result.registry.incidence);
            let deserializedNotifyTo = JSON.parse(registryState.result.registry.notifyTo);
            let stringNotifyTo = deserializedNotifyTo.filter((each) => {
                return each.Type == "email";
            }).map((each) => {
                return each.Target;
            }).join("; ");
            setNotifyToStringState(stringNotifyTo)
            setDataFormState(dataForm);
        } else {
            setRemoteDataState([]);
            setDataState([]);
            setDocsState([]);
            setRemoteDocsState([]);
            setExtraDocsState([]);
            setExtraRemoteDocsState([]);
            setIncidenceState("");
            setNotifyToStringState("");

            setDataFormState([]);
            setRemoteDataFormState([]);
        }
    }

    useEffect(() => {
        prepareData();
    }, [registryState]);

    useEffect(loadAll, [idRegistry, idDealer, idProcedure]);

    const [cmbFixState, setCmbFixState] = useState(false);

    return (
        <div className="registry-component">
            <div className="header">

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link replace={true} className="backButton" to={{ pathname: `/registries/${idDealer}`, comesFrom: history.location.pathname }}  >
                        <img src={imgGoBack} /> Ir atrás
                    </Link>
                    <div style={{ flex: "auto" }} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{!dealerState.loading ? dealerState.result.name : ""}</span>
                        <span style={{ fontSize: "12px", color: "#7A7A7A" }}>{!dealerState.loading ? dealerState.result.clienteDF : ""}</span>
                    </div>
                </div>

                <div className="header">
                    <h1>{registryState.loading ? "Cargando..." : `${String(registryState.result.registry.gestoria).padStart(3, "0")}${String(registryState.result.registry.procedureId).padStart(4, "0")}${String(registryState.result.registry.id).padStart(6, "0")}`}</h1>
                    <span style={{ marginLeft: 150, marginRight: 10 }}>Asignado: </span>
                    <SelectBox
                        value={userComboState}
                        onValueChanged={event => {
                            setUserComboState(event.component.option("value"))
                        }}
                        dataSource={usersState.loading ? [] : usersState.result}
                        valueExpr="id"
                        displayExpr="name"
                        disabled={!editionState || Number(sessionContext.user.userType) <= 0}
                    />
                    <span style={{ marginLeft: 40, marginRight: 10 }}>Estado: </span>
                    {/*fieldRender={renderStateField}*/}
                    <SelectBox
                        inputAttr={{
                            class: "state-combo"
                        }}
                        onValueChanged={event => {
                            setStateComboState(event.component.option("value"))
                        }}
                        value={stateComboState}
                        dataSource={stateDataSource}
                        valueExpr="state.id"
                        displayExpr="state.name"
                        //fieldRender={renderStateField}
                        itemRender={renderStateItem}
                        disabled={!editionState}
                        onContentReady={(e) => {
                            //e.component.open();
                            //e.component.close();
                        }}
                        onClosed={(e) => {
                            if (!cmbFixState) {
                                setCmbFixState(true);
                                e.component.open();
                            }
                        }}
                        onOpened={(e) => {
                            if (!cmbFixState) {
                                e.component.close();
                            }
                            let listItems = e.component.content().querySelectorAll(".dx-list-item-content");
                            listItems.forEach(function (listItem) {
                                listItem.style.padding = '0px';
                            });
                        }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                    <span style={{ marginRight: 10, minWidth: 250, maxWidth: 250 }}>Tipo: {registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null ? "Cargando..." : registryState.result.registry.procedureName}</span>
                    <span style={{ marginLeft: 150, marginRight: 10 }}>F. Alta: {registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null ? "Cargando..." : registryState.result.registry.createDate}</span>
                    <span style={{ marginLeft: 40, marginRight: 10 }}>F. Mod.: {registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null ? "Cargando..." : registryState.result.registry.changeDate}</span>
                    <span style={registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null || registryState.result.registry.closeDate == "" ? { marginLeft: 40, marginRight: 10 } : registryState.result.registry.aboutToGetDeleted ? { color: "var(--rojo-ko)", marginLeft: 40, marginRight: 10 } : { marginLeft: 40, marginRight: 10 }}>F. Cierre: {registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null || registryState.result.registry.closeDate == "" ? "-" : registryState.result.registry.closeDate}</span>
                    {/*<span style={{ marginLeft: 40, marginRight: 10 }}>Exportado a SIGA: </span>*/}
                    {/*<span>{registryState.loading || registryState.result == undefined || registryState.result == null || registryState.result.registry == undefined || registryState.result.registry == null ? <CheckBox value={false} disabled={true} /> : <CheckBox value={exportedSigaChkState} disabled={!editionState} onValueChanged={(e) => { setExportedSigaChkState(e.value) }} />}</span>*/}
                </div>
                {editionState
                    ?
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: "100%", }} className="error">{errorState}</div>
                        <div style={{ flex: "auto" }}></div>

                        <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0, backgroundColor: "white" }}
                            disabled={loadingState}
                            text="Salir"
                            stylingMode="outlined"
                            useSubmitBehavior={false}
                            onClick={event => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                showGenericDialog("Salir del trámite", `¿Estás seguro de que deseas salir? Si no has guardado los cambios, los perderás.`, "Si, salir", "No, cancelar", () => {
                                    hideGenericDialog();
                                    setEditionState(false);
                                    reload();
                                    let currentUrl = history.location.pathname;
                                    let backlog = [];
                                    if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                        backlog = history.location.state.comesFrom;
                                    }
                                    backlog.push(currentUrl);
                                    history.replace(`/registries/${idDealer}`, { comesFrom: backlog });
                                }, () => {
                                    hideGenericDialog();
                                });

                            }
                            }
                        />
                        <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                            disabled={loadingState}
                            text="Guardar"
                            type="default"
                            useSubmitBehavior={false}
                            onClick={async (event) => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                saveEdit(false);
                            }
                            }
                        />
                        <Button style={{ marginBottom: "20px", marginLeft: "50px", width: "auto", flexGrow: 0 }}
                            disabled={loadingState}
                            text="Guardar y salir"
                            type="default"
                            useSubmitBehavior={false}
                            onClick={event => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                saveEdit(true)
                            }
                            }
                        />

                    </div>
                    :
                    (!registryState.loading && registryState.result != undefined && registryState.result.registry != undefined && (Number(sessionContext.user.id) == Number(registryState.result.registry.assigned) || Number(sessionContext.user.userType) > 0)
                        ?
                        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                            {/*<Button style={{ marginBottom: "20px", marginRight: "10px", width: "auto", flexGrow: 0 }}*/}
                            {/*    disabled={loadingState}*/}
                            {/*    text="Exportar a SIGA"*/}
                            {/*    type="normal"*/}
                            {/*    useSubmitBehavior={false}*/}
                            {/*    onClick={event => {*/}
                            {/*        event.element.classList.remove("dx-state-active");*/}
                            {/*        event.element.classList.remove("dx-state-focused");*/}
                            {/*        exportRegistrySIGA();*/}
                            {/*    }*/}
                            {/*    }*/}
                            {/*/>*/}
                            <div style={{ flex: "auto" }}></div>
                            {(registryState.result.registry.stateId == 6) ?
                                <>
                                    <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        disabled={loadingState}
                                        text="Descargar"
                                        type="default"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            downloadRegistry();
                                        }
                                        }
                                    />
                                    <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        disabled={loadingState}
                                        text="Volver a abrir"
                                        type="default"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            setStateComboState(stateState.result.find((e2) => e2.initial).stateId);
                                            //setStateComboState(stateState.result[registryState.result.registry.procedureId].find((e2) => e2.initial).stateId);
                                            setEditionState(true);
                                        }
                                        }
                                    />
                                </>
                                :
                                <>
                                    <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        disabled={loadingState}
                                        text="Descargar"
                                        type="default"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            downloadRegistry();
                                        }
                                        }
                                    />
                                    <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        disabled={loadingState}
                                        text="Editar"
                                        type="danger"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            setEditionState(true);
                                        }
                                        }
                                        />
                                </>
                            }
                        </div>
                        :
                        <div style={{ height: 54 }}></div>
                    )
                }
            </div>
            <div className="body">
                <div className="data">
                    <h2>Datos</h2>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", flexBasis: "50%", paddingLeft: 20 }} >
                            <h4>Datos proporcionados por el concesionario</h4>
                            {
                                registryState.loading
                                    ?
                                    "Cargando..."
                                    : (
                                        <>
                                            <div key={`data_notify_to`} style={{ marginTop: "12px", paddingRight: "20px" }}>
                                                <label htmlFor={`data_notify_to`}>{`Noficar a: `}</label>
                                                <TextBox
                                                    name={`data_notify_to`}
                                                    placeholder=""
                                                    mode="text"
                                                    disabled={!editionState}
                                                    readonly={true}
                                                    value={notifyToStringState || ""}
                                                />
                                            </div>
                                            {(remoteDataState || []).map((d) => {
                                                return (
                                                    <div key={`data_${d[0].id}`} style={{ marginTop: "12px", paddingRight: "20px" }}>
                                                        <label htmlFor={`data_${d[0].id}`}>{`${d[0].name}: ${d[0].mandatory ? "*" : ""}`}</label>
                                                        <TextBox
                                                            name={`data_${d[0].id}`}
                                                            placeholder=""
                                                            disabled={true}
                                                            readonly={true}
                                                            mode="text"
                                                            value={remoteDataFormState.find(e => e[0] == String(d[0].id))[1]}
                                                            valueChangeEvent="keyup"
                                                            onValueChanged={event => {
                                                                setRemoteDataFormState(remoteDataFormState.map((e) => {
                                                                    if (e[0] != String(d[0].id)) {
                                                                        return e;
                                                                    } else {
                                                                        return [String(d[0].id), event.component.option("value")];
                                                                    }
                                                                })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )
                            }
                        </div>
                        <div style={{ width: "50%", flexBasis: "50%", borderLeft: "2px solid #A8CEE1", paddingLeft: 20 }} >
                            <h4>Datos a cumplimentar por la gestoría</h4>
                            {
                                registryState.loading
                                    ?
                                    "Cargando..."
                                    : (
                                        <>
                                            <div key={`data_incidence`} style={{ marginTop: "12px", paddingRight: "10px" }}>
                                                <label htmlFor={`data_incidence`}>{`Descripción/Incidencia (máx. 150 caracteres): `}</label>
                                                {/*<TextBox*/}
                                                {/*    name={`data_incidence`}*/}
                                                {/*    placeholder=""*/}
                                                {/*    mode="text"*/}
                                                {/*    disabled={!editionState}*/}
                                                {/*    readonly={!editionState}*/}
                                                {/*    value={incidenceState || ""}*/}
                                                {/*    valueChangeEvent="keyup"*/}
                                                {/*    onValueChanged={event => {*/}
                                                {/*        setIncidenceState(event.component.option("value"));*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                                <TextArea
                                                    name={`data_incidence`}
                                                    placeholder=""
                                                    mode="text"
                                                    height={100}
                                                    maxLength={150}
                                                    disabled={!editionState}
                                                    readonly={!editionState}
                                                    value={incidenceState || ""}
                                                    valueChangeEvent="keyup"
                                                    onValueChanged={event => {
                                                        //Limit to 150 characters
                                                        let value = event.component.option("value");
                                                        if (value.length > 150) {
                                                            value = value.substr(0, 150);
                                                        }
                                                        setIncidenceState(value);
                                                    }}
                                                />
                                            </div>
                                            {(dataState || []).map((d) => {
                                                return (
                                                    <div key={`data_${d[0].id}`} style={{ marginTop: "12px", paddingRight: "10px" }}>
                                                        <label htmlFor={`data_${d[0].id}`}>{`${d[0].name}: ${d[0].mandatory ? "*" : ""}`}</label>
                                                        <TextBox
                                                            name={`data_${d[0].id}`}
                                                            placeholder=""
                                                            mode="text"
                                                            disabled={!editionState}
                                                            readonly={!editionState}
                                                            value={dataFormState.find(e => e[0] == String(d[0].id))[1]}
                                                            valueChangeEvent="keyup"
                                                            onValueChanged={event => {
                                                                setDataFormState(dataFormState.map((e) => {
                                                                    if (e[0] != String(d[0].id)) {
                                                                        return e;
                                                                    } else {
                                                                        return [String(d[0].id), event.component.option("value")];
                                                                    }
                                                                })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="docs">
                    <h2>Documentos a aportar por el concesionario</h2>

                    <RemoteDocuments idRegistry={idRegistry} enabled={!registryState.loading && editionState} remoteProcedureDocuments={remoteDocsState} freeDocuments={extraRemoteDocsState} reload={loadRegistry} />
                    {/*<RegistryDocChooser itemsState={remoteDocsState} setItemsState={setRemoteDocsState} isRemote={true} withMandatory={true} disabled={!editionState || loadingState} />*/}

                    {/*<RegistryDocChooser itemsState={docsState} setItemsState={setDocsState} isRemote={false} withMandatory={true} disabled={!editionState || loadingState} />*/}
                </div>
                <div className="docs">
                    <h2>Documentos a aportar por la gestoría</h2>
                    <LocalDocuments idRegistry={idRegistry} enabled={!registryState.loading && editionState} localProcedureDocuments={docsState} freeDocuments={extraDocsState} reload={loadRegistry} />

                </div>
                <div className="events">
                    <h2>Eventos ocurridos sobre el trámite</h2>
                    <RegistryEvents idRegistry={idRegistry}  />

                </div>
            </div>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={genericDialogState.popupVisible}
                onHiding={hideGenericDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={genericDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {genericDialogState.koText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outline",
                            text: genericDialogState.koText,
                            onClick: genericDialogState.koCallback,
                        }}
                    />
                    :
                    null
                }
                {genericDialogState.okText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text: genericDialogState.okText,
                            onClick: genericDialogState.okCallback,
                        }}
                    />
                    :
                    null
                }
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    {genericDialogState.body}
                </p>
            </Popup>
            <LoadPanel
                shadingColor="rgb(92 92 92 / 80%)"
                visible={(loadingState || registryState.loading || stateState.loading || usersState.loading)}
                showIndicator={true}
                showPane={true}
                closeOnOutsideClick={false}
                indicatorSrc={imgSpinner}
                message="Cargando..."
            />
        </div>
    )
}
