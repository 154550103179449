import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Login } from './components/login/Login';
import { SessionContext } from './components/context/SessionContext';
import { PrivateRoute } from './components/routes/PrivateRoute';
import { PublicRoute } from './components/routes/PublicRoute';
import { PasswordRecovery } from './components/login/PasswordRecovery';

import './custom.css'
import { ConditionalPrivateRoute } from './components/routes/ConditionalPrivateRoute';
import { Users } from './components/users/Users';
import { States } from './components/states/States';
import { Procedures } from './components/procedures/Procedures';
import { Procedure } from './components/procedures/Procedure';
import { Dealers } from './components/dealers/Dealers';
import { Registries } from './components/registries/Registries';
import { Registry } from './components/registries/Registry';
import { History } from './components/history/History';
import { AssignedRegistries } from './components/registries/AssignedRegistries';


const App = () => {
    const [session, setSession] = useState({});
    const [loadingSession, setLoadingSession] = useState(true);

    //First session load
    useEffect(() => {
        const doWork = async () => {
            const response = await fetch(`${process.env.PUBLIC_URL}/api/session`);
            const data = await response.json();
            if (data.response !== null) {
                if (session != undefined && session != null && session.shownInitialAlert != undefined) {
                    data.response.shownInitialAlert = session.shownInitialAlert;
                }
                setSession(data.response);
            }
            setLoadingSession(false);
        }
        doWork();
    }, []);
    //Periodically check session
    useEffect(() => {
        const interval = setInterval(async () => {
            //if (session !== undefined && session !== null && session.isActive !== undefined) {
            const response = await fetch(`${process.env.PUBLIC_URL}/api/session`);
            const data = await response.json();
            if (data.response==null || (data.response !== undefined && data.response !== null && data.response.isActive !== undefined && data.response.isActive !== null && !data.response.isActive)) {
                setSession({ gestoria: null, user: null, isActive: false, shownInitialAlert: false });
            }

            //    if (data.response !== null) {
            //        setSession(data.response);
            //    } else {
            //        setSession({ gestoria: null, user:null, isActive: false});
            //    }
            //}
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    let sessionActive = false;
    if (session !== undefined && session !== null && session.isActive !== undefined) sessionActive = session.isActive;
    let isAdmin = false;
    let hasPerms = false;
    if (sessionActive && session.user.userType === 2) isAdmin = true;
    if (sessionActive && session.user.userType >= 1) hasPerms = true;
    return (
        <div className="app-component">
            {(loadingSession ?
                <div className="banner"></div>
                :
                <SessionContext.Provider value={{ session, setSession }} >
                    {/*<Layout>*/}
                    <Switch>
                        <PublicRoute isAuthenticated={sessionActive} exact path="/login" >
                            <Login />
                        </PublicRoute>
                        <PublicRoute isAuthenticated={sessionActive} exact path="/forgot" >
                            <PasswordRecovery />
                        </PublicRoute>
                        <PrivateRoute isAuthenticated={sessionActive} path="/" >
                            <Layout >
                                <Switch>
                                    <PrivateRoute isAuthenticated={sessionActive} path="/assigned" >
                                        <AssignedRegistries />
                                    </PrivateRoute>
                                    <ConditionalPrivateRoute isAuthenticated={sessionActive} condition={isAdmin} exact path="/states" >
                                        <States />
                                    </ConditionalPrivateRoute>
                                    <ConditionalPrivateRoute isAuthenticated={sessionActive} condition={isAdmin} exact path="/procedure/:id" >
                                        <Procedure />
                                    </ConditionalPrivateRoute>
                                    <ConditionalPrivateRoute isAuthenticated={sessionActive} condition={isAdmin} exact path="/procedures" >
                                        <Procedures />
                                    </ConditionalPrivateRoute>
                                    <ConditionalPrivateRoute isAuthenticated={sessionActive} condition={isAdmin} exact path="/users" >
                                        <Users />
                                    </ConditionalPrivateRoute>
                                    <ConditionalPrivateRoute isAuthenticated={sessionActive} condition={hasPerms} exact path="/history" >
                                        <History />
                                    </ConditionalPrivateRoute>
                                    <PrivateRoute isAuthenticated={sessionActive} path="/registries/:id" >
                                        <Registries />
                                    </PrivateRoute>
                                    <PrivateRoute isAuthenticated={sessionActive} path="/registry/:idDealer/:idProcedure/:idRegistry" >
                                        {/*<p>Hola amigo!</p>*/}
                                        <Registry />
                                    </PrivateRoute>
                                    <PrivateRoute isAuthenticated={sessionActive} path="/" >
                                        <Dealers />
                                    </PrivateRoute>
                                </Switch>
                            </Layout>
                        </PrivateRoute>
                        {/*<PublicRoute isAuthenticated={sessionActive} exact path="/login" component={Login} />*/}
                        {/*<PublicRoute isAuthenticated={sessionActive} exact path="/forgot" component={PasswordRecovery} />*/}
                        {/*<PrivateRoute isAuthenticated={sessionActive} path="/" component={Layout} children={<p>Hola amigo!</p>} />*/}
                        {/*<Layout>*/}
                        {/*<Switch>*/}
                        {/*    <PrivateRoute isAuthenticated={sessionActive} exact path="/state" >*/}
                        {/*        <p>Estado!</p>*/}
                        {/*    </PrivateRoute>*/}
                        {/*    <PrivateRoute isAuthenticated={sessionActive} path="/" >*/}
                        {/*        <p>Hola amigo!</p>*/}
                        {/*    </PrivateRoute>*/}
                        {/*</Switch>*/}
                        {/*<p>Hola amigo!</p>*/}
                        {/*</Layout>                    */}
                        {/*</PrivateRoute>*/}
                        {/*<Route exact path='/' component={Login} />*/}
                        {/*<Route path='/counter' component={Counter} />*/}
                        {/*<Route path='/fetch-data' component={FetchData} />*/}
                    </Switch>
                    {/*</Layout>*/}
                </SessionContext.Provider>
            )}
        </div>
    )
}
export default App;

