import { Button, CheckBox, List, Popup, TextBox } from 'devextreme-react';
import { ItemDragging } from 'devextreme-react/list';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react'
import "./ProcedureDataChooser.css";
import imgDelete from '../../img/delete.png';
import imgEdit from '../../img/edit.png';
import { InfoPopover } from '../common/InfoPopover';
import warning_big from '../../img/warning.png';

export const ProcedureDataChooser = ({ itemsState, setItemsState, withMandatory, withSync, isRemote, disabled, headerText, registryCount }) => {


    const editFormRef = useRef();
    const createFormRef = useRef();

    const [createDataState, setCreateDataState] = useState({ loading: false, mandatory: null, name: null, sync: null, isPlate: null, isChassis: null, error: false, result: null });
    const [editDataState, setEditDataState] = useState({ id: -1, loading: false, mandatory: null, name: null, sync: null, isPlate: null, isChassis: null, error: false, result: null });
    const [createDataDialogState, setCreateDataDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showCreateDialog = () => {
        setCreateDataDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (createFormRef != undefined && createFormRef != null && createFormRef.current != undefined && createFormRef.current != null) {
            createFormRef.current.setFieldValue("name", "", false);
            createFormRef.current.setFieldValue("mandatory", false, false);
            createFormRef.current.setFieldValue("sync", false, false);
            createFormRef.current.setFieldValue("isPlate", false, false);
            createFormRef.current.setFieldValue("isChassis", false, false);
        }
    }
    const hideCreateDialog = () => {
        setCreateDataDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const [editDataDialogState, setEditDataDialogState] = useState({
        id: 0,
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showEditDialog = (id) => {
        setEditDataDialogState({
            id: id,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (editFormRef != undefined && editFormRef != null && editFormRef.current != undefined && editFormRef.current != null) {
            let editingObject = itemsState.find((e) => e.id == id);
            if (editingObject != undefined && editingObject != null) {
                editFormRef.current.setFieldValue("name", editingObject.name, false);
                editFormRef.current.setFieldValue("sync", editingObject.sync, false);
                editFormRef.current.setFieldValue("mandatory", editingObject.mandatory, false);
                editFormRef.current.setFieldValue("isPlate", editingObject.isPlate, false);
                editFormRef.current.setFieldValue("isChassis", editingObject.isChassis, false);
            } else {
                hideEditDialog();
            }
        }
    }
    const hideEditDialog = () => {
        setEditDataDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }

    const validateCreateData = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Requerido';
        } else if (values.name.length > 150) {
            errors.name = 'Must be 150 characters or less';
        }

        return errors;
    };

    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const DXCheckInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <CheckBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const createFormOptions = {
        initialValues: {
            sync: false,
            name: '',
            mandatory: false
        },
        validate: validateCreateData,
        onSubmit: async values => {
            values.remote = isRemote;
            values.id = getNextId();
            setItemsState([...itemsState, values]);
            hideCreateDialog();
        }
    };
    const editFormOptions = {
        initialValues: {
            sync: false,
            name: '',
            mandatory: false
        },
        validate: validateCreateData,
        onSubmit: async values => {
            values.remote = isRemote;
            //setItemsState([...itemsState, values]);
            onDataChanged(editDataDialogState.id, values);
            hideEditDialog();
        }
    };

    const getNextId = () => {
        let nextId = Math.min.apply(Math, itemsState.map(function (o) { return o.id; }));
        if (nextId>0) {
            nextId = -1;
        } else {
            nextId = nextId - 1;
        }
        if (isRemote && nextId>-100) {
            nextId = nextId * 100;
        }
        return nextId;
    }

    const onAdd = () => {
        showCreateDialog();
        //setItemsState([...itemsState, { name: "nuevo", id: 0 }]);
    }
    const onDataChanged = (id, newData) => {
        const tasks = itemsState;
        const task = tasks.find((e) => e.id == id);
        const taskIndex = tasks.indexOf(task);
        
        task.name = newData.name;
        task.sync = newData.sync;
        task.mandatory = newData.mandatory;
        task.isPlate = newData.isPlate;
        task.isChassis = newData.isChassis;
        setItemsState(
            [...tasks.slice(0, taskIndex), task, ...tasks.slice(taskIndex + 1)],
        );
    }

    const [genericDialogState, setGenericDialogState] = useState({
        title: "",
        body: "",
        okText: "",
        koText: "",
        okCallback: () => {

        },
        koCallback: () => {

        },
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showGenericDialog = (title, body, okText, koText, okCallback, koCallback) => {
        setGenericDialogState({
            title: title,
            body: body,
            okText: okText,
            koText: koText,
            okCallback: okCallback,
            koCallback: koCallback,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideGenericDialog = () => {
        setGenericDialogState({
            title: "",
            body: "",
            okText: "",
            koText: "",
            okCallback: () => {

            },
            koCallback: () => {

            },
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }
    const deleteData = (id) => {
        const tasks = itemsState;
        const task = tasks.find((e) => e.id == id);
        const taskIndex = tasks.indexOf(task);

        setItemsState(
            [...tasks.slice(0, taskIndex), ...tasks.slice(taskIndex + 1)],
        );
    }

    return (
        <div className="procedure-data-chooser-component">
            <h4 style={{ marginBottom: 3 }} >{headerText}</h4>
            <div style={disabled ? ({ float: "right", textDecoration: "underline", cursor: "default", marginBottom: 0, opacity: "0.5" }) : ({ float: "right", textDecoration: "underline", cursor: "pointer", marginBottom: 0 })} disabled={disabled} onClick={!disabled?onAdd:null} >Añadir nuevo dato</div>

            <div className="listHeader" style={{ marginBottom: 10, width: "calc(100% - 90px)", cursor: "default", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                <div style={{ width: "100%" }}>Nombre</div>
                {(withMandatory ?
                    <div style={{ width: "100%" }}>Obligatorio</div>
                    :
                    <div style={{ width: "100%" }}></div>)
                }
                {(withSync ?
                    <div style={{ width: "100%" }}>Sincronizable<InfoPopover elementId="infoSincronizable" content="Sincronizable: Los datos marcados como sincronizables,  serán datos que se sincronizarán si el concesionario o la gestoría los modifican y se mostrarán siempre actualizados." /></div>
                    :
                    <div style={{ width: "100%" }}></div>)
                }
                <div style={{ width: "90px" }}></div>
            </div>
            {itemsState.length > 0 ?
                itemsState.map((e) => {
                    return <div key={e.id && e.name} style={{ width: "100%", display: "flex", marginBottom:3 }}>
                        <div style={disabled ? { width: "100%", display: "flex", padding: 5, border: "solid var(--azul-df) 0.5px", opacity: "0.5", alignItems: "center" } : { width: "100%", display: "flex", padding: 5, border: "solid var(--azul-df) 0.5px", alignItems: "center" }}>
                            <b style={{ width: "100%" }} >{e.name}</b>
                            {withMandatory ?
                            <div style={{ width: "100%" }} >{(e.mandatory ? "Sí" : "No")}</div>
                            :
                            <div style={{ width: "100%" }}></div>
                            }
                            {withSync ?
                                <div style={{ width: "100%" }} >{(e.sync ? "Sí" : "No")}</div>
                                :
                                <div style={{ width: "100%" }}></div>
                            }
                        </div>
                        <div style={{ width: "80px", display: "flex" }}>
                            <div style={{flex:"auto"}} />
                            <Button
                                width={40}
                                style={{ marginLeft: 10 }}
                                disabled={disabled}
                                elementAttr={{"data-id":e.id}}
                                icon={imgEdit}
                                type="normal"
                                stylingMode="contained"
                                onClick={(e) => showEditDialog(e.element.dataset.id)}
                            />
                            <Button
                                width={40}
                                style={{ marginLeft: 10 }}
                                disabled={disabled}
                                icon={imgDelete}
                                type="normal"
                                stylingMode="contained"
                                onClick={() => {
                                    showGenericDialog("Eliminar dato", [`¿Estás seguro de que deseas eliminar el dato `, <><b>{e.name}</b>?<br /></>, "Los datos asignados a trámites de este tipo dejarán de estar disponibles permanentemente."], "Si, eliminar", "No, cancelar", () => {
                                        hideGenericDialog();
                                        showGenericDialog("Eliminar dato",
                                        [<div style={{ display: "flex", "justifyContent": "center" }}><img src={warning_big} /></div>,
                                        <h4 style={{ "textAlign": "center", "color": "var(--azul-df)", "marginTop": "7px", "marginBottom": "0px" }} >
                                            Eliminar la entrada de dato <b>'{e.name}'</b> afectará a {registryCount} trámites.
                                        </h4>,
                                        <p style={{ "textAlign": "center", "color": "var(--azul-df)", "marginTop": "12px" }} >¿Sigues estando seguro de eliminar la entrada de dato <b>{e.name}</b>?</p>
                                        ], "Eliminar permanentemente", "Cancelar", () => {
                                            deleteData(e.id);
                                            hideGenericDialog();
                                        }, () => { hideGenericDialog() })
                                    }, () => { hideGenericDialog() })
                                }}
                            />
                        </div>
                    </div>
                })
                :
                <div style={{ width: "100%", textAlign: "center", padding: 5, border: "solid var(--azul-df) 0.5px", fontFamily:"roboto-regular", marginTop: 20}}>Sin datos</div>
            }
            
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={createDataDialogState.popupVisible}
                onHiding={hideCreateDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Nuevo dato"
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth="400"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...createFormOptions}
                    innerRef={createFormRef}  >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.name && props.errors.name) || (props.touched.sync && props.errors.sync) || (props.touched.mandatory && props.errors.mandatory);
                            //const hasFetchError = (!createDataState.loading && createDataState.email == props.values.email && createDataState.name == props.values.name && createDataState.type == props.values.type && (createDataState.result == null || createDataState.error));
                            const hasError = (hasInputError);
                            return <Form className="create-user-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "procedure-data-chooser-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                {withMandatory ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <label style={{ marginTop: "12px", width: "100%" }} htmlFor="mandatory">Obligatorio: </label>
                                        <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="mandatory" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                    </div>
                                    :
                                    null
                                }
                                {withSync ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <label style={{ marginTop: "12px", width: "100%" }} htmlFor="sync">Sincronizable: </label>
                                        <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="sync" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                    </div>
                                    :
                                    null
                                }

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label style={{ marginTop: "12px", width: "100%" }} htmlFor="isPlate">Es la matrícula: </label>
                                    <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="isPlate" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                </div>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label style={{ marginTop: "12px", width: "100%" }} htmlFor="isChassis">Es el bastidor: </label>
                                    <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="isChassis" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                </div>

                                {hasError ?
                                    <div className="procedure-data-chooser-error">
                                        {props.touched.name && props.errors.name && props.errors.name == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            createDataState.error ? createDataState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("sync", false, false);
                                                setFieldTouched("name", false, false);
                                                setFieldTouched("mandatory", false, true);
                                                setFieldTouched("isPlate", false, false);
                                                setFieldTouched("isChassis", false, false);
                                                hideCreateDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                
                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>


            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={editDataDialogState.popupVisible}
                onHiding={hideEditDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Editar dato"
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth="400"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...editFormOptions}
                    innerRef={editFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.name && props.errors.name) || (props.touched.sync && props.errors.sync) || (props.touched.mandatory && props.errors.mandatory);
                            //const hasFetchError = (!editDataState.loading && editDataState.email == props.values.email && editDataState.name == props.values.name && editDataState.type == props.values.type && (editDataState.result == null || editDataState.error));
                            const hasError = (hasInputError);
                            return <Form className="create-user-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "procedure-data-chooser-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                {withMandatory ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <label style={{ marginTop: "12px", width: "100%" }} htmlFor="mandatory">Obligatorio: </label>
                                        <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="mandatory" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                    </div>
                                    :
                                    null
                                }
                                {withSync ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <label style={{ marginTop: "12px", width: "100%" }} htmlFor="sync">Sincronizable: </label>
                                        <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="sync" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                    </div>
                                    :
                                    null
                                }

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label style={{ marginTop: "12px", width: "100%" }} htmlFor="isPlate">Es la matrícula: </label>
                                    <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="isPlate" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                </div>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label style={{ marginTop: "12px", width: "100%" }} htmlFor="isChassis">Es el bastidor: </label>
                                    <DXCheckInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="isChassis" fieldType="boolean" className={hasError ? "procedure-data-chooser-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                </div>

                                {hasError ?
                                    <div className="procedure-data-chooser-error">
                                        {props.touched.name && props.errors.name && props.errors.name == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            editDataState.error ? editDataState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            handleReset();
                                            setFieldTouched("sync", false, false);
                                            setFieldTouched("name", false, false);
                                            setFieldTouched("mandatory", false, true);
                                            setFieldTouched("isPlate", false, false);
                                            setFieldTouched("isChassis", false, false);
                                            hideEditDialog();
                                        }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");

                                        }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={genericDialogState.popupVisible}
                onHiding={hideGenericDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={genericDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {genericDialogState.koText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outline",
                            text: genericDialogState.koText,
                            onClick: genericDialogState.koCallback,
                        }}
                    />
                    :
                    null
                }
                {genericDialogState.okText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text: genericDialogState.okText,
                            onClick: genericDialogState.okCallback,
                        }}
                    />
                    :
                    null
                }
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    {genericDialogState.body}
                </p>
            </Popup>
        </div>
    )
}
