import { Button, LoadPanel, Popup } from 'devextreme-react'
import React, { useContext, useEffect, useState } from 'react'
import "./Dealers.css";
import { useHistory } from 'react-router-dom';
import { Dealer } from './Dealer';
import warning_big from '../../img/warning.png';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { SessionContext } from '../context/SessionContext';
import imgSpinner from '../../img/spinner.gif';

export const Dealers = () => {

    let history = useHistory();


    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);
    const [loadingDealersState, setLoadingDealersState] = useState(true);
    const [loadingCountsState, setLoadingCountsState] = useState(true);
    const [dealersState, setDealersState] = useState([]);
    const [nextToExpireState, setNextToExpireState] = useState({ dialogShown: false, expiredList: [], loaded: false });


    const [dialogState, setDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showDialog = (title, header, body) => {
        setDialogState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideDialog = () => {
        setDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }

    const [dialogExpirationState, setDialogExpirationState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showDialogExpiration = (title, header, body) => {
        setDialogExpirationState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideDialogExpiration = () => {
        setDialogExpirationState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }

    const [dialogChangeStateState, setDialogChangeStateState] = useState({
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
        cbOk: () => { },
        cbKo: () => { },
        state: 0,
        dealer: null
    });
    const showDialogChangeState = (dealer, state, cbOk, cbKo) => {
        setDialogChangeStateState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
            cbOk: cbOk,
            cbKo: cbKo,
            state: state,
            dealer: dealer
        });
    }
    const hideDialogChangeState = () => {
        setDialogChangeStateState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const loadCloseToExpire = async () => {
        if (sessionContext != undefined && sessionContext != null && !sessionContext.shownInitialAlert) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/GetCloseToExpire`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response);
                    setNextToExpireState({ dialogShown: true, expiredList: result.response, loaded: true });
                    if (result.response != undefined && result.response != null && result.response.length != undefined && result.response.length > 0) {
                        let newSession = sessionContext;
                        newSession.shownInitialAlert = true;
                        setSessionContext(newSession)
                        showDialogExpiration("Aviso importante",
                            `${result.response.length} trámites finalizados serán borrados pronto.`,
                            "Revisa la fecha de cierre y, si no lo hiciste antes, descárgalos. Los trámites se borrarán a los 2 meses de haber sido finalizados.");
                    }
                    //setDealersState(result.response);
                })
                .catch(() => {
                    setNextToExpireState({ dialogShown: false, expiredList: [], loaded: true });
                    throw 'Network error'
                });
        }
    };
    const firstLoadStates = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/dealers/Get?withCounters=0`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                result.response.sort(function (a, b) { return b.estado - a.estado });
                setDealersState(result.response)
                setLoadingDealersState(false);
                loadStatesWithCounters();
            })
            .catch(() => { throw 'Network error' });
    };
    const loadStatesWithCounters = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/dealers/Get?withCounters=1`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setLoadingCountsState(false);
                result.response.sort(function (a, b) { return b.estado - a.estado });
                setDealersState(result.response)
            })
            .catch(() => { throw 'Network error' });
    };
    const setDealerState = (dealer, state) => {
        showDialogChangeState(dealer, state, () => {
            setDealerStateRequest(dealer, state);
            hideDialogChangeState();
        }, () => {
            hideDialogChangeState();
        });
    }
    const setDealerStateRequest = (dealer, state) => {
        setLoadingDealersState(true);
        fetch(`${process.env.PUBLIC_URL}/api/dealers/PutState?id=${dealer.id}&state=${state}`,
            {
                method: 'PUT',
                body: '',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                if (result.response != null) {
                    firstLoadStates();
                }
            })
            .catch(() => {
                throw 'Network error'
                setLoadingDealersState(false);
            });
    }


    useEffect(() => { firstLoadStates() }, []);
    useEffect(() => { loadCloseToExpire() }, [sessionContext]);

    return (
        <div className="dealers-component">
            <div className="container">
                <div className="flex">
                    <div className="header">
                        <h1>Concesionarios</h1>
                        <div className="space" style={{flex: 1}} />
                        {/*<Button style={{ marginRight: "70px", width: "auto", flexGrow: 0 }}*/}
                        {/*    disabled={false}*/}
                        {/*    text="Mis trámites"*/}
                        {/*    type="outlined"*/}
                        {/*    useSubmitBehavior={false}*/}
                        {/*    onClick={async (event) => {*/}
                        {/*            // navigate to ./assigned*/}
                        {/*            history.push("/assigned");*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*/>*/}
                    </div>
                    <div className="body">
                        {
                            dealersState.map((e) => {
                                if (e.estado==1 || (sessionContext.user.userType === 2 && e.estado==0)) {
                                    return <Dealer key={e.id} dealer={e} loadingCounters={loadingCountsState} setDealerState={setDealerState} />
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={dialogExpirationState.popupVisible}
                onHiding={hideDialogExpiration}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={dialogExpirationState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth={ 500 }
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideDialogExpiration();
                        },
                    }}
                />
                <div style={{ display: "flex", "justifyContent": "center" }}><img src={warning_big} /></div>
                <p style={{"textAlign": "center", "color": "var(--azul-df)", "marginBottom": "0px"}} >Aviso importante</p>
                <h4 style={{ "textAlign": "center", "color": "var(--azul-df)", "marginTop": "7px", "marginBottom": "0px" }} >
                    {dialogExpirationState.header}
                </h4>
                <p style={{ "textAlign": "center", "color": "var(--azul-df)", "marginTop": "12px" }} >Revisa la fecha de cierre y, si no lo hiciste antes, descárgalos.</p>
                <p style={{ "textAlign": "center", "fontSize": "smaller", "color": "var(--azul-df)" }} >
                    Los trámites se borrarán a los 2 meses de haber sido finalizados.
                </p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={dialogChangeStateState.popupVisible}
                onHiding={hideDialogChangeState}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={`${dialogChangeStateState.state == 1 ? "Aprobar" : "Rechazar"} concesionario ${dialogChangeStateState.dealer!=null?dialogChangeStateState.dealer.name:"NO NAME"}`}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {/*{dialogChangeStateState.koText != null ?*/}
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outline",
                            text: "Cancelar",
                            onClick: dialogChangeStateState.cbKo,
                        }}
                    />
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
                {/*{dialogChangeStateState.okText != null ?*/}
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text: dialogChangeStateState.state == 1 ? "Aprobar" : "Rechazar",
                            onClick: dialogChangeStateState.cbOk,
                        }}
                    />
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    {`¿Estás seguro de que deseas ${dialogChangeStateState.state == 1 ? "aprobar" : "rechazar"} el concesionario ${dialogChangeStateState.dealer?dialogChangeStateState.dealer.name:"NO NAME"}?`}
                </p>
            </Popup>
            <LoadPanel
                shadingColor="rgb(92 92 92 / 80%)"
                visible={loadingDealersState}
                showIndicator={true}
                showPane={true}
                closeOnOutsideClick={false}
                indicatorSrc={imgSpinner}
                message="Cargando..."
            />
        </div>
    )
}
